var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":_vm.getMerchant.logoUrl,"variant":"light-primary","size":"90px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex mb-1 align-items-center"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getMerchant.name)+" ")]),_c('b-badge',{staticClass:"ml-1",attrs:{"variant":_vm.statusVariant(_vm.getMerchant.vendorState).color}},[_vm._v(" "+_vm._s(_vm.statusVariant(_vm.getMerchant.vendorState).text)+" ")])],1),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file","accept":"image/png, image/jpeg, image/tiff"},on:{"change":function($event){return _vm.handleFileUpload($event)}}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Загрузить")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1)],1),_c('div',[(_vm.imageRequired === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"full-name"}},[_c('b-form-input',{attrs:{"id":"full-name","state":errors.length > 0 ? false : null},model:{value:(_vm.getMerchant.name),callback:function ($$v) {_vm.$set(_vm.getMerchant, "name", $$v)},expression:"getMerchant.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Категория","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Категория","label-for":"merchant-category"}},[_c('v-select',{attrs:{"options":_vm.getCategories.categories,"state":errors.length > 0 ? false : null,"label":"titleRu","input-id":"merchant-category","placeholder":"Выберите категории","multiple":""},on:{"input":_vm.changeCategoryList},model:{value:(_vm.getMerchant.vendorCategoryList),callback:function ($$v) {_vm.$set(_vm.getMerchant, "vendorCategoryList", $$v)},expression:"getMerchant.vendorCategoryList"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Статус","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Статус","label-for":"merchant-status"}},[_c('v-select',{attrs:{"options":_vm.statusOptions,"state":errors.length > 0 ? false : null,"clearable":false,"input-id":"merchant-status"},model:{value:(_vm.getMerchant.vendorState),callback:function ($$v) {_vm.$set(_vm.getMerchant, "vendorState", $$v)},expression:"getMerchant.vendorState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание RU","label-for":"merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-ru","placeholder":"Описание","state":errors.length > 0 ? false : null,"rows":"7"},model:{value:(_vm.getMerchant.vendorDescriptionRu),callback:function ($$v) {_vm.$set(_vm.getMerchant, "vendorDescriptionRu", $$v)},expression:"getMerchant.vendorDescriptionRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание UZ","label-for":"merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"7"},model:{value:(_vm.getMerchant.vendorDescriptionUz),callback:function ($$v) {_vm.$set(_vm.getMerchant, "vendorDescriptionUz", $$v)},expression:"getMerchant.vendorDescriptionUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание EN","label-for":"merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-en","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"7"},model:{value:(_vm.getMerchant.vendorDescriptionEn),callback:function ($$v) {_vm.$set(_vm.getMerchant, "vendorDescriptionEn", $$v)},expression:"getMerchant.vendorDescriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }